import React from 'react';
import {
  Switch,
  Redirect,
} from 'react-router';
import {
  createStyles,
  makeStyles,
  Container,
  Theme,
} from '@material-ui/core';

import { ProtectedRoute, MainBar } from '../components';
import { DashboardRoutes } from "../routes/dashboardRoutes";
import { useSelector } from 'react-redux';
import { selectAuth } from '../context/auth/state/selectors';

const useStyles = makeStyles(({ palette }: Theme) => createStyles({
  root: {
    backgroundColor: '#F1F2F5',
    minHeight: '100vh',
    flexDirection: 'column',
  },
  body: {
    width: '100%',
    backgroundColor: '#FFF',
    padding: 'unset',
    height: '100%',
  },
}), { name: 'PagesLayout' });

const DashboardLayout = () => {
  const classes = useStyles();

  const auth = useSelector(selectAuth);
  const isAuth = !!auth.tokens.access_token
  return (
    <div className={classes.root}>
      {isAuth && <MainBar />}
      <Container maxWidth="lg">
        <Switch>
          {DashboardRoutes.map((route) => {
            if (route.redirect) {
              const from = Array.isArray(route.path)
                ? route.path[0]
                : route.path;
              const to = Array.isArray(route.redirect)
                ? route.redirect[0]
                : route.redirect;

              if (to) {
                return (
                  <Redirect
                    from={from}
                    to={to}
                    key={`${from}_${to}`}
                  />
                );
              }
              return null;
            }
            if (route.path)

              return (
                <ProtectedRoute
                  exact
                  {...route}
                  key={JSON.stringify(route.path)}
                />
              );
          })}
        </Switch>
      </Container>
    </div>
  );
};

export default DashboardLayout;
