import React, { useCallback } from 'react';
import {Checkbox, Grid, TableCell, TableRow, Typography} from '@material-ui/core';
import { PermMediaOutlined, LockOutlined } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { formatBytes } from '../../../../infrastructure/helpers/helpers';

import useStyles from './styles';
import { getFoldersListRequest, getFoldersListRequestUnauthorized } from "../../state/actions";
import {FileManagerRouteGetter} from "../../infrastructure/routeGetter";
import { selectFolderMetaData, selectPathToRootFolders } from '../../state/selectors';
import { useLocation } from 'react-router';

type Props = {
  folder: any;
  isSelected: boolean;
  handleSelect: () => void;
  folderIndex: number;
};

const FolderRow = ({ folder, folderIndex, isSelected, handleSelect }: Props) => {
  const dispatch = useDispatch();
  const folderMetaData = useSelector(selectFolderMetaData)
  const pathToRootFolders = useSelector(selectPathToRootFolders)

  const styles = useStyles();
  const labelId = `enhanced-table-checkbox-${folderIndex}`;

  const { pathname } = useLocation();
  const folderPath = folder.path;

  const isSecureFolder = !!folder.secure?.length;
  const isVinFolderPage = pathname.startsWith(FileManagerRouteGetter.vin)

  const onClickHandler = useCallback(() => {
    const isRootPath = folderMetaData.level === 0
    if (isRootPath) {
      // if (isVinFolderPage) {
      //   const filterQuery = '?filter='
      //   const vin = pathname?.split('/').pop()
      //   const newPath = vin + '/' + folderPath
      //   const filteredPath = folderPath + filterQuery + folderMetaData.folderName

      //   dispatch(push(newPath));
      //   dispatch(getFoldersListRequestUnauthorized(filteredPath));
      // } else {
      const filteredByVin = '?filter=' + folderMetaData.folderName
      dispatch(
        push(FileManagerRouteGetter.getFolderPath(folderPath)),
      );
      dispatch(getFoldersListRequest(folderPath + filteredByVin));
      // }
    } else {
      // if (isVinFolderPage) {
      //   dispatch(push(pathname + '/' + folder.folderName));
      //   dispatch(getFoldersListRequestUnauthorized(folderPath));
      // } else {
      if (pathToRootFolders) {
        dispatch(     
          push(
            FileManagerRouteGetter.getFolderPath(folderPath),
          ),
        );
        dispatch(getFoldersListRequest(folderPath.trim()));
      } else {
        dispatch(
          push(
            FileManagerRouteGetter.getFolderPath(folderPath),
          ),
        );
        dispatch(getFoldersListRequest(folderPath.trim()));
      }
      // }
    }

  }, [dispatch, folderPath, folderMetaData, isVinFolderPage, pathname])

  return (
    <TableRow
      onClick={onClickHandler}
      role="checkbox"
      aria-checked={isSelected}
      tabIndex={-1}
      key={folder.folderId}
      selected={isSelected}
      className={styles.row}
    >
      <TableCell padding="checkbox">
        <Checkbox
          onClick={(e: React.MouseEvent) => e.stopPropagation()}
          onChange={handleSelect}
          checked={isSelected}
          inputProps={{ 'aria-labelledby': labelId }}
          classes={{
            root: styles.checkBoxRoot,
            checked: styles.checkBoxRoot,
          }}
        />
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        <Grid item xs={12}>
          <Typography display="inline">
            <PermMediaOutlined className={styles.folderIcon} />
            {isSecureFolder && <LockOutlined className={styles.folderIcon} />}
          </Typography>
          <Typography display="inline">{folder.folderName}</Typography>
        </Grid>
        <Grid item xs={12} className={styles.secondaryText}>
          <Typography display="inline" color="textSecondary">
            Папок: {folder.totalFolders}&nbsp;|&nbsp;
          </Typography>
          <Typography display="inline" color="textSecondary">
            Файлов: {folder.totalFiles}&nbsp;|&nbsp;
          </Typography>
          <Typography display="inline" color="textSecondary">
            (Размер: {formatBytes(folder.totalSizeByte || 0)})
          </Typography>
        </Grid>
      </TableCell>
    </TableRow>
  );
}

export default FolderRow;
