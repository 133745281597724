import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { FolderOpen } from '@material-ui/icons';

import DownloadAction from './actions/DownloadAction';
import CarStyles from './styles';
import CopyPathAction from './actions/CopyPathAction';
import { useLocation } from 'react-router';
import { FileManagerRouteGetter } from '../infrastructure/routeGetter';

type Props = {
  folderName: string;
  date: string;
  totalSize: string;
};

const Header = ({ folderName, date, totalSize }: Props) => {
  const styles = CarStyles();
  const { pathname } = useLocation()
  const isSecureFolderPage = pathname.startsWith(FileManagerRouteGetter.secure)
  const isVinFolderPage = pathname.startsWith(FileManagerRouteGetter.vin)

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography display="inline" variant="h4">
          <FolderOpen className={styles.folderTitleIcon} />
        </Typography>
        <Typography display="inline" variant="h4">{folderName}</Typography>
      </Grid>
      <Grid item container justify="space-between">
        <Grid item>
          <Typography>
            Размер: {totalSize}
          </Typography>
          <Typography>
            Изменен: {!Date.parse(date) ? '-' : date}
          </Typography>
        </Grid>
        <Grid item>
          {(isSecureFolderPage || isVinFolderPage) && <CopyPathAction />}
          <DownloadAction isSecure={isSecureFolderPage} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Header;
