export class FileManagerRouteGetter {
  static readonly index = '/zeus/folder';
  static readonly secure = '/folder/secure/'
  static readonly vin = '/auction-photo/vin/'

  static getFolderPath(path: string) {
    if (path.startsWith(this.secure)) {
      return path;
    }
    if (path === '') {
      return this.index;
    }
    if (path.startsWith('/')) {
      path = path.substring(1);
    }
    return `${this.index}/${path}`;
  }
}
