import React, { useCallback, useState } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch, useSelector } from 'react-redux';

import { ModalConfirm } from '../../../../../components';
import { deleteManyFilesRequest } from '../../../state/actions';

import useStyles from './styles';
import { selectAuth } from '../../../../auth/state/selectors';


interface PropsButton {
  fileId: number;
  onClick?: () => void;
}

const RemoveAction = ({ fileId, onClick }: PropsButton) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const auth = useSelector(selectAuth);
  const isAuth = Boolean(auth.tokens.access_token);

  const [confirmOpened, setConfirmOpened] = useState(false);

  const deleteFile = useCallback(() => {
    dispatch(deleteManyFilesRequest([fileId]));
  }, [fileId, dispatch]);

  const clickHandler = useCallback(() => {
    setConfirmOpened(true);
  }, [setConfirmOpened]);

  const closeHandler = useCallback(() => {
    setConfirmOpened(false);
  }, [setConfirmOpened]);

  const confirmHandler = useCallback(() => {
    closeHandler();
    deleteFile();
    if (onClick) {
      onClick();
    }
  }, [closeHandler, deleteFile, onClick]);

  return (
    <>
      {isAuth &&
        <Tooltip title="Удалить">
        <IconButton aria-label="delete" onClick={clickHandler}>
          <DeleteIcon className={classes.icon} />
        </IconButton>
      </Tooltip>}
      <ModalConfirm
        open={confirmOpened}
        onClose={closeHandler}
        onCancel={closeHandler}
        onConfirm={confirmHandler}
        text={`Удалить файл?`}
      />
    </>
  );
};

export default RemoveAction;
