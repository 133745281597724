import React, {
  useContext, useCallback, useState, useMemo,
} from 'react';
import {
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from '@material-ui/core';
import { useSwipeable } from 'react-swipeable';
import {useLocation, useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {replace} from "connected-react-router";

import { FilesViewerContext } from '../../../../infrastructure/hooks/FilesViewerContext';
import CloseAction from './actions/closeAction';
import NextAction from './actions/nextAction';
import PreviousAction from './actions/previousAction';
import RemoveAction from './actions/RemovePreviewFileAction';

import FileStyles from './styles';
import {FileEntity} from "../../infrastructure/obligations/fileEntity";
import {RouteService} from "../../infrastructure/routeService";
import {FileManagerRouteGetter} from "../../infrastructure/routeGetter";
import { selectCurrentFile } from '../../state/selectors';
import { setCurrentFile } from '../../state/actions';

type Props = {
  files: FileEntity[];
};

/* eslint-disable react-hooks/exhaustive-deps */
const FileViewer = ({ files }: Props) => {
  //@ts-ignore
  const {'0': routePath} = useParams();
  const dispatch = useDispatch();
  const selectedFile = useSelector(selectCurrentFile)
  const { pathname } = useLocation()
  const isSecureFolderPage = pathname.startsWith(FileManagerRouteGetter.secure)
  const pathForSecureFolderPage = pathname.split('/').slice(1, -1).pop();
  const previousVinFolderPath = routePath?.split('/').slice(0, -1).join('/')

  const styles = FileStyles();
  const [error, setError] = useState(false);
  const {
    index, setIndex,
  } = useContext(FilesViewerContext);

  const currentFile = useMemo(() => {
    if (isSecureFolderPage) {
       return selectedFile
     }
     if (typeof routePath === 'string') {
       const fileName = RouteService.getFileName(routePath);
       if (fileName) {
         const file = files.find((item) => item.fileName === fileName);
         return file;
       }
     }

     return undefined;
  }, [files, routePath, isSecureFolderPage, selectedFile])

  const totalFiles = files?.length || 0;

  const onError = useCallback(() => {
    setError(true);
  }, [setError]);

  const nextFile = useCallback(() => {
    let nextIndex = index + 1;

    if (nextIndex === totalFiles) {
      nextIndex = 0;
    }

    setError(false);
    setIndex(nextIndex);
    if (isSecureFolderPage) {
      const file = files[nextIndex];
      const newPath = FileManagerRouteGetter.secure + pathForSecureFolderPage + '/' + file.fileName
      dispatch(setCurrentFile(file))
      dispatch(replace(newPath));
    }
    if (typeof routePath === 'string' && !isSecureFolderPage) {
      const file = files[nextIndex];
      const newPath = FileManagerRouteGetter.getFolderPath(`${RouteService.getFolderPath(routePath)}/${file.fileName}`);
      dispatch(replace(newPath));
    }
  }, [setIndex, index, totalFiles, files, setError, dispatch, routePath, isSecureFolderPage, pathForSecureFolderPage, setCurrentFile, previousVinFolderPath]);

  const previousFile = useCallback(() => {
    let previousIndex = index - 1;

    if (previousIndex < 0) {
      previousIndex = totalFiles - 1;
    }

    setError(false);
    setIndex(previousIndex);
    if (isSecureFolderPage) {
      const file = files[previousIndex];
      const newPath = FileManagerRouteGetter.secure + pathForSecureFolderPage + '/' + file.fileName
      dispatch(setCurrentFile(file))
      dispatch(replace(newPath));
    }
    if (typeof routePath === 'string' && !isSecureFolderPage) {
      const file = files[previousIndex];
      const newPath = FileManagerRouteGetter.getFolderPath(`${RouteService.getFolderPath(routePath)}/${file.fileName}`);
      dispatch(replace(newPath));
    }
  }, [setIndex, index, totalFiles, setError, dispatch, routePath, files, isSecureFolderPage, pathForSecureFolderPage, previousVinFolderPath]);

  const swipeable = useSwipeable({
    onSwipedLeft: nextFile,
    onSwipedRight: previousFile,
    delta: 200,
  });

  const closeFileViewer = useCallback(() => {
    if (isSecureFolderPage) {
      const newPath = FileManagerRouteGetter.secure + pathForSecureFolderPage
      dispatch(replace(newPath));
      dispatch(setCurrentFile(null))
    }
    if (typeof routePath === 'string' && !isSecureFolderPage) {
      const newPath = FileManagerRouteGetter.getFolderPath(`${RouteService.getFolderPath(routePath)}`);
      dispatch(replace(newPath));
  }
  }, [dispatch, routePath, isSecureFolderPage, pathForSecureFolderPage]);

  const keyHandler = useCallback((e: React.KeyboardEvent) => {
    switch (e.key) {
      case 'Escape':
      case 'Esc':
        e.preventDefault();
        closeFileViewer();
        break;
      case 'ArrowRight':
        e.preventDefault();
        nextFile();
        break;

      case 'ArrowLeft':
        e.preventDefault();
        previousFile();
        break;

      default:
        break;
    }
  }, [closeFileViewer, nextFile, previousFile, previousVinFolderPath]);

  if (!currentFile) {
    return null;
  }

  return (
    <Dialog
      open={true}
      fullScreen
      onKeyDown={keyHandler}
      disablePortal
      PaperProps={{
        className: styles.paperRoot,
      }}
    >
      <DialogContent>
        <Grid container justify="flex-end">
          {currentFile && <RemoveAction fileId={currentFile.id} onClick={closeFileViewer} />}
          <CloseAction onClick={closeFileViewer} />
        </Grid>
        <Grid container alignItems="center" justify="space-between" {...swipeable}>
          <PreviousAction onClick={previousFile} />
          <Grid item container className={styles.gallery}>
            {error
              && (
                <Typography paragraph style={{ color: '#FFF' }}>
                  Не удается получить файл. Возможно, файл еще не был загружен на CDN. Пожалуйста, подождите 1 мин.
                </Typography>
              )}
            <img src={currentFile.url} alt="" onError={onError} />
          </Grid>
          <NextAction onClick={nextFile} />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default FileViewer;
