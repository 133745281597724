//@ts-nocheck
import { combineReducers } from 'redux';

import { FileManagerState, FileManagerAction } from './types';
import { Reducer } from '../../../state/types';
import {
  getFoldersListRequest,
  getFoldersListSuccess,
  getFoldersListFail,
  clearFoldersState,
  searchFoldersListRequest,
  searchFoldersListSuccess,
  searchFoldersListFail,
  setCurrentFolderMetaData,
  setSearchFolderData,
  deleteManyFilesSuccess,
  getSecureFolderRequest,
  setCurrentFile,
  getFolderByVinRequest,
  getFoldersListRequestUnauthorized,
  setPathToRootFolders,
} from './actions';

type ReducerOf<T extends keyof FileManagerState> = Reducer<FileManagerState, FileManagerAction, T>;

const data: ReducerOf<'data'> = (state = null, action) => {
  switch (action.type) {

    case getFoldersListSuccess.type:
    case searchFoldersListSuccess.type:
    case getSecureFolderRequest.type:
    case getFolderByVinRequest.type:
    case getFoldersListRequestUnauthorized.type:  
      return action.payload;

    case deleteManyFilesSuccess.type:
      if (state) {
        state.files = state.files.filter((item) => !action.payload.includes(item.id));
        return {...state}
      }
      return state

    case clearFoldersState.type:
      return null;

    default:
      return state;
  }
};

const loading: ReducerOf<'loading'> = (state = false, action) => {
  switch (action.type) {
    case getFoldersListRequest.type:
    case searchFoldersListRequest.type:
      return true;

    case getFoldersListSuccess.type:
    case searchFoldersListSuccess.type:
    case getFoldersListFail.type:
    case searchFoldersListFail.type:
      return false;

    default:
      return state;
  }
};

const error: ReducerOf<'error'> = (state = '', action) => {
  switch (action.type) {
    case getFoldersListRequest.type:
    case searchFoldersListRequest.type:
    case getFoldersListSuccess.type:
    case searchFoldersListSuccess.type:
      return '';

    case getFoldersListFail.type:
    case searchFoldersListFail.type:
      return action.payload;

    default:
      return state;
  }
};

const metaData: ReducerOf<'metaData'> = (state = {level: 0, path: '/', folderName: '/', folderId: 0}, action) => {
  switch (action.type) {
    case setCurrentFolderMetaData.type:
      return action.payload;

    case clearFoldersState.type:
      return  {level: 0, path: '/', folderName: '/', folderId: 0};

    default:
      return state;
  }
}

const isSearch: ReducerOf<'isSearch'> = (state = false, action) => {
  switch (action.type) {
    case setSearchFolderData.type:
      return action.payload;

    default:
      return state;
  }
}

const currentFile: ReducerOf<'currentFile'> = (state = null, action) => {
  switch (action.type) {
    case setCurrentFile.type:
      return action.payload;

    default:
      return state;
  }
}

const pathToRooTFolders: ReducerOf<'pathToRooTFolders'> = (state = false, action) => {
  switch (action.type) {
    case setPathToRootFolders.type:
      return action.payload;

    default:
      return state;
  }
}

export default combineReducers<FileManagerState>({
  data,
  metaData,
  loading,
  error,
  isSearch,
  currentFile,
  pathToRooTFolders,
});
