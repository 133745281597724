//@ts-nocheck
import { put, takeLatest } from 'redux-saga/effects';

import history from '../../../state/history';
import AuthService from './authRequester';
import apiService from '../../../infrastructure/requester';
import { apiCall } from '../../../state/utils/effects';
import {
  loginRequest,
  loginFail,
  loginSuccess,
  logOut,
  initAuth,
  initAuthSuccess,
  initAuthFail,
} from './actions';
import { getErrorNotification } from '../../../state/utils/helpers';
import { isAccessToken } from '../../../infrastructure/helpers/helpers';
import { notify } from '../../../state/context/notifications/actions';
import {PagesRouteGetter} from "../../../routes/routeGetter";
import { FileManagerRouteGetter } from '../../file-manager/infrastructure/routeGetter';

function* login({ payload }: ReturnType<typeof loginRequest>) {
  try {
    const response = yield apiCall(AuthService.login, payload);
    yield put(loginSuccess(response));
  } catch (e) {
    yield put(notify(getErrorNotification(e)));
    yield put(loginFail(e.message));
    console.error(e);
  }
}

function loginSuccessSaga({ payload }: ReturnType<typeof loginSuccess>) {
  localStorage.setItem('auth-tokens', JSON.stringify(payload));
  const { access_token } = payload;
  apiService.setToken(access_token);
}

function logout({ payload }: ReturnType<typeof logOut>) {
  apiService.setToken(null);
  localStorage.removeItem('auth-tokens');

  if (payload.folderName) {
    const isSecureFolderPage = payload.path.startsWith(FileManagerRouteGetter.secure)
    const isVinFolderPage = payload.path.startsWith(FileManagerRouteGetter.vin)
    
    if (isSecureFolderPage) {
      history.push(FileManagerRouteGetter.secure + payload.folderName);
    }
    if (isVinFolderPage) {
      history.push(FileManagerRouteGetter.vin + payload.folderName);
    }
  } else {
    history.push(PagesRouteGetter.login);
  }
}

function* init() {
  try {
    const tokens = JSON.parse(localStorage.getItem('auth-tokens') as string) || {};
    if (tokens.access_token && isAccessToken(tokens.access_token)) {
      apiService.setToken(tokens.access_token);
      yield put(initAuthSuccess(tokens));
    } else {
      yield put(initAuthFail());
    }
  } catch (e) {
    yield put(initAuthFail());
    console.error(e);
  }
}


export default [
  takeLatest(initAuth.type, init),
  takeLatest(loginRequest.type, login),
  takeLatest(logOut.type, logout),
  takeLatest(loginSuccess.type, loginSuccessSaga),
];
