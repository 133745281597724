import { action, withPayload, withoutPayload } from '../../../state/utils/redux';
import { LoginRequest } from './loginRequest';
import { LogOut } from './logout';
import { Token } from './types';

export const loginRequest = action(
  'LOGIN_REQUEST',
  withPayload<LoginRequest>(),
);

export const loginSuccess = action(
  'LOGIN_SUCCESS',
  withPayload<Token>(),
);

export const loginFail = action(
  'LOGIN_FAIL',
  withPayload<string>(),
);

export const logOut = action(
  'LOGOUT',
  withPayload<LogOut>(),
);

export const initAuth = action(
  'INIT_AUTH',
  withoutPayload,
);

export const initAuthSuccess = action(
  'INIT_AUTH_SUCCESS',
  withPayload<Token>(),
);

export const initAuthFail = action(
  'INIT_AUTH_FAIL',
  withoutPayload,
);
