import apiService, { Requester } from '../../../infrastructure/requester';
import { DownloadArchiveRequest, ClearFoldersRequest, SearchFolderRequest } from '../infrastructure/obligations/request';
import {FolderEntity} from "../infrastructure/obligations/folderEntity";

class FolderRequester {
  constructor(private api: Requester) {}

  getByPath = (path: string) => {
    return this.api.get<FolderEntity>(`/olympus/folder/${path}`);
  };

  search = (params: SearchFolderRequest) => {
    return this.api.post<FolderEntity>(`/olympus/folder/search`, params);
  };

  getSecureFolder = (params: string) => {
    return this.api.get<FolderEntity>(`api/folder/secure/${params}`);
  }

  getFolderByVin = (params: string) => {
    return this.api.get<FolderEntity>(`api/folder/vin/${params}`);
  }

  getFolder = (params: string) => {
    return this.api.get<FolderEntity>(`api/folder/${params}`);
  }

  downloadArchive = (params: DownloadArchiveRequest) => {
    return this.api.get(`/olympus/file/download?folderId=${params.folderId}&token=${params.token}&isSecure=${params.isSecure}`);
  };

  deleteManyFiles = (ids: number[]) =>
    this.api.delete('/olympus/file/many', { ids });

  clearFolders = (params: ClearFoldersRequest) =>
    this.api.post('/olympus/folder/clear', params);
}

export default new FolderRequester(apiService);
