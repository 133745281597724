import React, { useCallback, useState } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch, useSelector } from 'react-redux';

import { ModalConfirm } from '../../../../../components';
import { deleteManyFilesRequest } from '../../../state/actions';
import useStyles from './styles';
import { useLocation } from 'react-router';
import { FileManagerRouteGetter } from '../../../infrastructure/routeGetter';
import { selectAuth } from '../../../../auth/state/selectors';


interface PropsButton {
  selected: number[];
}

const RemoveSelectedFilesAction = ({ selected }: PropsButton) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const auth = useSelector(selectAuth);
  const isAuth = Boolean(auth.tokens.access_token);
  
  const [confirmOpened, setConfirmOpened] = useState(false);

  const deleteSelectedFiles = useCallback(() => {
    dispatch(deleteManyFilesRequest(selected));
  }, [selected, dispatch]);

  const clickHandler = useCallback(() => {
    setConfirmOpened(true);
  }, [setConfirmOpened]);

  const closeHandler = useCallback(() => {
    setConfirmOpened(false);
  }, [setConfirmOpened]);

  const confirmHandler = useCallback(() => {
    closeHandler();
    deleteSelectedFiles();
  }, [closeHandler, deleteSelectedFiles]);

  return (
    <>
      {isAuth &&
        <Tooltip title="Удалить">
        <IconButton
          aria-label="delete"
          onClick={clickHandler}
          classes={{
            root: classes.buttonRoot,
          }}
        >
          <DeleteIcon className={classes.deleteIcon} />
        </IconButton>
      </Tooltip>}
      <ModalConfirm
        open={confirmOpened}
        onClose={closeHandler}
        onCancel={closeHandler}
        onConfirm={confirmHandler}
        text={`Удалить ${selected.length} файл(а/ов)?`}
      />
    </>
  );
};

export default RemoveSelectedFilesAction;
