//@ts-nocheck
import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  createStyles,
  makeStyles,
  Theme,
  useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

import { useId } from '../../infrastructure/hooks';

type Props = {
  open: boolean;
  title?: React.ReactNode;
  text?: React.ReactNode;
  confirmText?: React.ReactNode;
  cancelText?: React.ReactNode;
  onClose?: () => void;
  onConfirm?: () => void;
  onCancel?: () => void;
};

const useStyles = makeStyles(({ colors }: Theme) => createStyles({
  modal: {
    border: `2px solid ${colors.border}`,
  },
}));

const ModalConfirm = ({
  open,
  title = 'Подтвердите действие',
  text = 'Вы хотите продолжить?',
  confirmText = 'Подтвердить',
  cancelText = 'Отмена',
  onClose = () => {},
  onConfirm = () => {},
  onCancel = () => {},
}: Props) => {
  const theme = useTheme<Theme>();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const id = useId();
  const classes = useStyles();

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      aria-labelledby={id}
      style={{ zIndex: 2147483100 }}
      PaperProps={{ className: classes.modal }}
    >
      <DialogTitle id={id}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary" variant="contained">
          {cancelText}
        </Button>
        <Button autoFocus onClick={onConfirm} color="primary" variant="contained">
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalConfirm;
