import React, {useCallback, useContext} from 'react';
import { Checkbox, TableCell, TableRow } from '@material-ui/core';
import { Image } from '@material-ui/icons';
import moment from 'moment';

import { FilesViewerContext } from '../../../../infrastructure/hooks/FilesViewerContext';
import { formatBytes } from '../../../../infrastructure/helpers/helpers';

import FileStyles from './styles';
import {FileEntity} from "../../infrastructure/obligations/fileEntity";
import {FileManagerRouteGetter} from "../../infrastructure/routeGetter";
import {RouteService} from "../../infrastructure/routeService";
import {replace} from "connected-react-router";
import {useLocation, useParams} from "react-router";
import {useDispatch} from "react-redux";
import { setCurrentFile } from '../../state/actions';

type Props = {
  file: FileEntity;
  fileIndex: number;
  isSelected: boolean;
  handleSelect: () => void;
};

const FileRow = ({ file, fileIndex, isSelected, handleSelect }: Props) => {
  //@ts-ignore
  const {'0': routePath} = useParams();
  const dispatch = useDispatch();
  const { pathname } = useLocation()
  const isSecureFolderPage = pathname.startsWith(FileManagerRouteGetter.secure)
  const isVinFolderPage = pathname.startsWith(FileManagerRouteGetter.vin)

  const styles = FileStyles();
  const { setIsOpen, setIndex } = useContext(FilesViewerContext);
  const labelId = `enhanced-table-checkbox-${fileIndex}`;
  
  const onClickHandler = useCallback(() => {
    dispatch(setCurrentFile(file))
    setIndex(fileIndex);
    setIsOpen(true);
    if (isSecureFolderPage) {
      const newPath = FileManagerRouteGetter.getFolderPath(`${FileManagerRouteGetter.secure}${RouteService.getFolderPath(routePath)}/${file.fileName}`);
      dispatch(replace(newPath));
    }
    if (isVinFolderPage) {
      const newPath = pathname + '/' + file.fileName
      dispatch(replace(newPath));
    }
    if (typeof routePath === 'string' && !isSecureFolderPage && !isVinFolderPage) {
      const newPath = FileManagerRouteGetter.getFolderPath(`${RouteService.getFolderPath(routePath)}/${file.fileName}`);
      dispatch(replace(newPath));
    }
  }, [setIsOpen, setIndex, fileIndex, file, routePath, dispatch, isSecureFolderPage, isVinFolderPage, pathname]);

  return (
    <TableRow
      onClick={onClickHandler}
      role="checkbox"
      aria-checked={isSelected}
      tabIndex={-1}
      key={file.id}
      selected={isSelected}
      className={styles.row}
    >
      <TableCell padding="checkbox">
        <Checkbox
          onClick={(e: React.MouseEvent) => e.stopPropagation()}
          onChange={handleSelect}
          checked={isSelected}
          inputProps={{ 'aria-labelledby': labelId }}
          classes={{
            root: styles.checkBoxRoot,
            checked: styles.checkBoxRoot,
          }}
        />
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        <Image className={styles.imageIcon} />
        {file.fileName}
      </TableCell>
      <TableCell align="right">
        {moment(file.updatedAt).format('DD-MM-YYYY HH:mm:ss')}
      </TableCell>
      <TableCell align="right">
        {formatBytes(file.sizeByte)}
      </TableCell>
    </TableRow>
  );
}

export default FileRow;
