import React, { useState, useCallback, useEffect } from 'react';
import {
  makeStyles, createStyles, Theme, InputBase, Button, Grid,
} from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { clearFoldersState, setPathToRootFolders } from '../../context/file-manager/state/actions';
import { replace } from 'connected-react-router';
import { FileManagerRouteGetter } from '../../context/file-manager/infrastructure/routeGetter';
import { selectFolderData, selectFolderMetaData } from '../../context/file-manager/state/selectors';

//@ts-ignore
const useStyles = makeStyles(({ colors, palette }: Theme) => createStyles({
  container: {
    backgroundColor: '#F9FAFB',
    border: `1px solid ${colors.border}`,
    borderRadius: '5px',
    height: 36,
    minWidth: 250,
  },
  inputRoot: {
    flexGrow: 1,
  },
  inputInput: {
    '&::placeholder': {
      color: palette.text.primary,
    },
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
  },
  icon: {
    width: 20,
    height: 20,
    color: colors.icon,
  },
  button: {
    textTransform: 'unset',
  },
}), { name: 'SearchBar' });

type Props = {
  placeholder?: string;
  fetch: (value: string) => void;
};

const SearchBar = ({ fetch, placeholder = 'Поиск' }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { data, loading, error, isSearch } = useSelector(selectFolderData);
  const [searchText, setSearchText] = useState('');
  const folderMetaData = useSelector(selectFolderMetaData);

  const fetchHandler = useCallback(() => {
    dispatch(clearFoldersState())
    dispatch(replace(FileManagerRouteGetter.index))
    fetch(searchText);
  }, [fetch, searchText]);

  const keyDownHandler = useCallback(({ key }: React.KeyboardEvent) => {
    if (key === 'Escape') {
      setSearchText('');
    }
    if (key === 'Enter') {
      fetchHandler();
    }
  }, [setSearchText, fetchHandler]);

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  }, [setSearchText]);
  
  useEffect(() => {
    if (folderMetaData.level === 0 && data !== null && typeof data !== 'string') {
      dispatch(setPathToRootFolders(true))
    } else {
      dispatch(setPathToRootFolders(false))
    }
  }, [fetch, folderMetaData, data])

  return (
    <div style={{ margin: 10 }}>
      <Grid
        container
        justify="center"
        spacing={2}
      >
        <Grid item>
          <InputBase
            className={classes.container}
            placeholder={placeholder}
            value={searchText}
            onChange={onChange}
            onKeyDown={keyDownHandler}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ 'aria-label': 'search' }}
            startAdornment={<SearchIcon className={classes.icon} />}
          />
        </Grid>
        <Grid item>
          <Button
            onClick={fetchHandler}
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Найти
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default SearchBar;
