//@ts-nocheck
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useHistory, useLocation, useParams } from 'react-router';

import { Breadcrumbs, Link, LoadingFrame, SearchBar } from '../../../components';
import { formatBytes } from '../../../infrastructure/helpers/helpers';
import { getFoldersListRequest, searchFoldersListRequest, clearFoldersState, getSecureFolderRequest, getFolderByVinRequest, getFoldersListRequestUnauthorized, setPathToRootFolders } from '../state/actions';
import { selectFolderData, selectFolderMetaData, selectRootFolder } from '../state/selectors';
import Header from './Header';
import FoldersComponent from "./folder/FoldersComponent";
import FilesComponent from "./file/FilesComponent";
import { FileManagerRouteGetter } from "../infrastructure/routeGetter";
import { RouteService } from "../infrastructure/routeService";
import { selectAuth } from '../../auth/state/selectors';
import { Box, createStyles, makeStyles } from '@material-ui/core';
import { push, replace } from 'connected-react-router';
import { PagesRouteGetter } from '../../../routes/routeGetter';

const useStyles = makeStyles(createStyles({
  wrapper: {
    minHeight: '70vh',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }
}, { name: 'FileManager' }))

const FileManager = () => {
  const { '0': routePath } = useParams();
  const { data, loading, error, isSearch } = useSelector(selectFolderData);
  const folderMetaData = useSelector(selectFolderMetaData);
  const fileName = routePath?.split('/').pop(); //shift
  const classes = useStyles();
  const { action } = useHistory()
  const { pathname } = useLocation()
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!error) {
      dispatch(replace(PagesRouteGetter.notFound))
    }
  }, [dispatch, error])

  const auth = useSelector(selectAuth);
  const isAuth = !!auth.tokens.access_token

  const isSecureFolderPage = pathname.startsWith(FileManagerRouteGetter.secure)

  const fetch = useCallback((path: string) => {
    if (!path && typeof routePath === 'string' && routePath !== '/') {
      dispatch(getFoldersListRequest(routePath));
    } else {
      dispatch(searchFoldersListRequest({
        path: path,
        pathPosition: folderMetaData.level + 1,
      }));
    }
  }, [dispatch, folderMetaData, routePath]);

  useEffect(() => {
    if (isSecureFolderPage) {
      if (!loading && !error) {
        const newPath = FileManagerRouteGetter.secure + routePath
        if (data === null || action === 'POP') {
          dispatch(getSecureFolderRequest(routePath));
          dispatch(replace(newPath))
        }
      }
    }
    if (folderMetaData.path !== '/' && !data?.files?.length) {
      dispatch(replace(FileManagerRouteGetter.getFolderPath(folderMetaData.path)))
    }
    if (folderMetaData.path !== '/' && !data?.files?.length && isSecureFolderPage) {
      dispatch(replace(FileManagerRouteGetter.getFolderPath(FileManagerRouteGetter.secure + folderMetaData.path)))
    }
    if (typeof routePath === 'string' && !isSecureFolderPage) {
      if (!loading && !error) { 
        const folderPath = RouteService.getFolderPath(routePath);
        if (data === null || (!isSearch && folderMetaData.path !== folderPath)) {
          dispatch(getFoldersListRequest(routePath));
        }
      }
    }
  }, [dispatch, data, error, loading, routePath, folderMetaData, isSearch, isSecureFolderPage, fileName]);

  const totalSize = useMemo(() => {
    let totalBytes = 0;

    if (data !== null && data?.totalSizeByte) {
      totalBytes = data.totalSizeByte;
    }

    return formatBytes(totalBytes);
  }, [data?.totalSizeByte]);

  const date = useMemo(() => {
    if (data !== null) {
      return moment(data.createdAt).format('DD-MM-YYYY HH:mm:ss');
    }
    return '';
  }, [data?.createdAt]);

  const breadcrumbs = useMemo(() => {
    const result: any[] = [];
    if (typeof routePath === "string" && data?.path) {
      const paths = data?.path.split('/');
      let to = '';
      paths.forEach((item) => {
        if (item) {
          if (!RouteService.isFilePath(item)) {
            to += `/${item}`;
            result.push({
              title: item,
              onClick: () => dispatch(getFoldersListRequest(to)),
              to: FileManagerRouteGetter.getFolderPath(to),
            });
          }
        }
      });
    }

    return result
  }, [routePath, dispatch, data, isSecureFolderPage, isAuth]);

  const toRootDir = useCallback(() => {
    dispatch(clearFoldersState());
  }, [dispatch]);

  return (
    <>
      <Box className={!isAuth ? classes.wrapper : {}}>
        {loading && <LoadingFrame />}
        {isAuth && <Link to={FileManagerRouteGetter.index} onClick={toRootDir} style={{ fontSize: 20 }}>
          Главная
        </Link>}
        {isAuth && <SearchBar fetch={fetch} />}
        {(data !== null && !error) && (
          <>
            <Header date={date} totalSize={totalSize} folderName={folderMetaData.folderName} />
            {(isAuth) && <Breadcrumbs items={breadcrumbs} />}
            {(!!data?.children?.length) && (<FoldersComponent data={data} />)}
            {(!!data?.files?.length) && (<FilesComponent files={data.files} />)}
          </>
        )}
      </Box>
    </>
  );
};

export default FileManager;
