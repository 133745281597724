import React, { useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';

import { ActionMenu, ActionItem } from '../index';
import { logOut } from '../../context/auth/state/actions';
import { selectAuth } from '../../context/auth/state/selectors';

const MainBar = () => {
  //@ts-ignore  
  const { '0': routePath } = useParams();
  const { pathname } = useLocation()
  const dispatch = useDispatch();
  const auth = useSelector(selectAuth);
  const isAuth = Boolean(auth.tokens.access_token);
  const folderName = routePath?.split('/').shift()

  const logOutHandler = useCallback(() => {
    dispatch(logOut({ path: pathname, folderName: folderName }));
  }, [dispatch, pathname, folderName]);

  return (
    <Grid container>
      <Grid item style={{ marginLeft: 'auto' }}>
        <ActionMenu>
          {isAuth && (
            <ActionItem onClick={logOutHandler}>
              Выйти
            </ActionItem>
          )}
        </ActionMenu>
      </Grid>
    </Grid>
  );
};

export default MainBar;
