//@ts-nocheck
import { call, put, select } from 'redux-saga/effects';

import { logOut } from '../../context/auth/state/actions';
import { setPreviousRoute } from '../context/common/actions';
import { selectAccessToken } from '../../context/auth/state/selectors';

import history from '../history';
import {PagesRouteGetter} from '../../routes/routeGetter';
import { isAccessToken } from '../../infrastructure/helpers/helpers';

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */

export function* apiCall<FN extends(...args: any[]) => any>(
  method: FN,
  ...args: Parameters<FN>) {
  try {
    return yield call(method, ...args);
  } catch (e) {
    throw e;
  }
}

export function* apiCallWithRedirect<FN extends(...args: any[]) => any>(
  method: FN,
  ...args: Parameters<FN>) {
  try {
    return yield call(method, ...args);
  } catch (e) {
    if (e.response?.status === 404) {
      history.push(PagesRouteGetter.notFound);
    }

    throw e;
  }
}

export function* isAccessAuth() {
  try {
    const token = yield select(selectAccessToken);
    if (token) {
      return isAccessToken(token);
    }
    return false;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export function* apiCallAuth<FN extends(...args: any[]) => any>(
  method: FN,
  ...args: Parameters<FN>) {
  try {
    const accessAuth = yield isAccessAuth();
    if (accessAuth) {
      return yield call(method, ...args);
    }
    const currentRoute = `${window.location.pathname}${window.location.search}`;
    yield put(setPreviousRoute(currentRoute));
    yield put(logOut());
    throw new Error('Unauthorized');
  } catch (e) {
    if (e.response?.status === 401) {
      yield put(logOut());
    }

    throw e;
  }
}

export function* apiCallWithRedirectAuth<FN extends(...args: any[]) => any>(
  method: FN,
  ...args: Parameters<FN>) {
  try {
    const accessAuth = yield isAccessAuth();
    if (accessAuth) {
      return yield call(method, ...args);
    }
    const currentRoute = `${window.location.pathname}${window.location.search}`;
    yield put(setPreviousRoute(currentRoute));
    yield put(logOut());
    throw new Error('Unauthorized');
  } catch (e) {
    if (e.response?.status === 401) {
      yield put(logOut());
    }

    if (e.response?.status === 404) {
      history.push(PagesRouteGetter.notFound);
    }

    throw e;
  }
}
