//@ts-nocheck
import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
  colors: {
    backgroundColor: '#F1F2F5',
    border: '#DCDEE5',
    icon: '#747C99',
  },
  palette: {
    primary: {
      main: '#FF6C00',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#26325F',
    },
    text: {
      primary: '#26325F',
      secondary: '#515B6A',
    },
    icons: {
      default: '#747C99',
    },
    table: {
      border: '#DCDEE5',
      background: '#FFF',
      header: {
        background: '#F9FAFB',
        hover: '#01B3C5',
        color: '#26325F',
      },
      row: {
        hover: 'rgba(1, 179, 197, 0.08)',
      },
    },
    buttonSingle: {
      color: '#01B3C5',
      hover: 'rgba(1,179,197,0.1)',
    },
  },

});

export default theme;
