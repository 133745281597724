import React, { useCallback } from 'react';
import { Button, Tooltip } from '@material-ui/core';
import { Archive, BlockOutlined } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';

import { selectAccessToken } from '../../../auth/state/selectors';
import { logOut } from '../../../auth/state/actions';
import { downloadFile, isAccessToken } from '../../../../infrastructure/helpers/helpers';
import CarStyles from '../styles';
import {API_URL} from "../../../../infrastructure/obligations/constants";
import {selectFolderMetaData} from "../../state/selectors";

type Props = {
  isSecure: boolean
};

const DownloadAction = ({ isSecure }: Props) => {
  const styles = CarStyles();
  const dispatch = useDispatch();
  const folderMetaData = useSelector(selectFolderMetaData);
  const token = useSelector(selectAccessToken);

  // Disabled for allow download zip by unauthorized user
  // const downloadHandler = useCallback(() => {
  //   if (isAccessToken(token)) {
  //     let url = `${API_URL}/olympus/file/download?folderId=${folderMetaData.folderId}&token=${token}`;
  //     downloadFile(url, `${folderMetaData.folderName}_${Date.now()}.zip`);
  //   } else {
  //     dispatch(logOut());
  //   }
  // }, [dispatch, token, folderMetaData]);

  const downloadHandler = useCallback(() => {
      let url = `${API_URL}/olympus/file/download?folderId=${folderMetaData.folderId}&token=${token}&isSecure=${isSecure}`;
      downloadFile(url, `${folderMetaData.folderName}_${Date.now()}.zip`);
  }, [dispatch, token, folderMetaData]);

  return (
    <>
      {folderMetaData.folderId ? <Button
      onClick={downloadHandler}
      variant="outlined"
      startIcon={<Archive />}
      className={styles.button}
    >
      Скачать архив
    </Button> :
    <Tooltip title='Зайдите в кореневую папку' placement='top'>
    <span>
    <Button
      onClick={downloadHandler}
      variant="outlined"
      startIcon={<BlockOutlined/>}
      className={styles.button}
      disabled
    >
      Скачать архив
    </Button>
    </span>
    </Tooltip>}
    </>
  );
};

export default DownloadAction;
