import React from 'react';
import { Button } from '@material-ui/core';
import { FileCopy } from '@material-ui/icons';
import CarStyles from '../styles';

const CopyPathAction = () => {
  const styles = CarStyles();
  const additionalStyles = {
    margin: 25
  }
  
  const copyPathHandler = async () => {
    if (window.isSecureContext && navigator.clipboard) {
      await navigator.clipboard.writeText(window.location.href);
  } else {
      const textArea = document.createElement("textarea");
      textArea.value = window.location.href;

      document.body.appendChild(textArea);
      textArea.focus({preventScroll: true})
      textArea.select();

      try {
          document.execCommand('copy');
      } catch (err) {
          console.error('Unable to copy to clipboard', err);
      }
      document.body.removeChild(textArea);
  }
  }
    
  return (
    <Button
      onClick={copyPathHandler}
      variant="outlined"
      startIcon={<FileCopy />}
      className={styles.button}
      style={additionalStyles}
    >
      Скопировать ссылку
    </Button>
    )
}

export default CopyPathAction;